import React from 'react';

import { Spring } from 'react-spring';
import Layout from '../layouts/en';
import SEO from '../components/seo';
import MainSection from '../styles/MainSection';

const NotFoundPage = props => (
  <Layout {...props}>
    <SEO title="404: Not found" />
    <MainSection>
      <Spring from={{ opacity: 0, bottom: -40 }} to={{ opacity: 1, bottom: 0 }}>
        {springProps => (
          <div className="container" style={springProps}>
            <h1>NOT FOUND</h1>
            <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
          </div>
        )}
      </Spring>
    </MainSection>
  </Layout>
);

export default NotFoundPage;
